import { Form, Formik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import FormControl from '../components/form/FormControl'
import SkeletonBoarding from './SkeletonBoarding'
import SubmitButton from '../components/SubmitButton'
import { MainLanguageContext } from '../context/MainLanguageContext'
import useGet from '../customHooks/useGet'
import usePost from '../customHooks/usePost'
import { toast } from 'react-toastify'
import swal from "sweetalert";
import profile from "../dist/webImages/profile2.png"
import camera from "../dist/webImages/camera.svg"

const Boarding = () => {
  const { mainLanguage } = useContext(MainLanguageContext);
  const [loading, setLoading] = useState(true)
  const [resget, apiMethodGet] = useGet()
  const [image, setImage] = useState({
    "Onboarding1":{
      "image":"",
      "imageValue":"",
    },
    "Onboarding2":{
      "image":"",
      "imageValue":"",
    },
    "Onboarding3":{
      "image":"",
      "imageValue":"",
    },
  })
  const {Onboarding1,Onboarding2,Onboarding3} = image
  useEffect(() => {
    if (mainLanguage) {
      setLoading(true);
      apiMethodGet(`appcontent/onboard`);
    }
  }, [mainLanguage]);

  const [datas, setDatas] = useState()
  useEffect(() => {
    if (!resget.isLoading) {
      setLoading(false)
      let aa = resget?.data?.data
      setImage((prevData) => ({
        "Onboarding1": {
          "image":aa?.onboarding1_image?.image,
          "imageValue":"",
        },
        "Onboarding2": {
          "image":aa?.onboarding2_image?.image,
          "imageValue":"",
        },
        "Onboarding3": {
          "image":aa?.onboarding3_image?.image,
          "imageValue":"",
        },
      }))
    }
  }, [resget.data])



  const handleFileUpload = (e,type) => {
    const file = e.target.files[0];
    if (file) {
      let path = (window.URL || window.webkitURL).createObjectURL(file);
      setImage((prevData) => ({
        ...prevData,
        [type]: {
          ...prevData[type],
          image: path,
          imageValue: file,
        }
      }));
    }
  }
  const handleDrop = (e,type) => {
    e.preventDefault();
    if (e.dataTransfer.files[0] && e.dataTransfer.files[0]?.type?.includes("image")) {
      let path = (window.URL || window.webkitURL).createObjectURL(e.dataTransfer.files[0]);

      const file = e.dataTransfer.files[0];
      setImage((prevData) => ({
        ...prevData,
        [type]: {
          ...prevData[type],
          image: path,
          imageValue: file,
        }
      }));
    }
    else {
      swal("Only use Image", "", "warning");
    }
  };


  const [res, apiMethod] = usePost()
  const requireFeild = [
    "onboarding1_heading",
    "onboarding1_paragraph",
    "onboarding2_heading",
    "onboarding2_paragraph",
    "onboarding3_heading",
    "onboarding3_paragraph",
  ];
  const handleSubmit = (values) => {
    let formdata = new FormData();
    let requireFeildSwal = {
      onboarding1_heading: "onboarding1 Heading",
      onboarding1_paragraph: "onboarding1 Paragraph",
      onboarding2_heading: "onboarding2 Heading",
      onboarding2_paragraph: "onboarding2 Paragraph",
      onboarding3_heading: "onboarding2 Heading",
      onboarding3_paragraph: "onboarding2 Paragraph",
    };
    let checkerRequried = [];
    for (const item in values) {
      if (requireFeild.includes(item) && values[item] === "") {
        checkerRequried.push(requireFeildSwal[item]);
      }
      formdata.append(item, values[item]);
    }

    if (checkerRequried.length > 0) {
      swal({
        title: "Required Fields are empty! Please fill and try again",
        text: checkerRequried.join(","),
        icon: "error",
        dangerMode: true,
      });
    }

    else {
      formdata.append("onboarding1_image", Onboarding1?.imageValue ?? Onboarding1?.image);
      formdata.append("onboarding2_image", Onboarding2?.imageValue ?? Onboarding1?.image);
      formdata.append("onboarding3_image", Onboarding3?.imageValue ?? Onboarding1?.image);
      formdata.append("onboarding1_old_image", resget?.data?.data?.onboarding1_image?.old_image ?? "");
      formdata.append("onboarding2_old_image", resget?.data?.data?.onboarding2_image?.old_image ?? "");
      formdata.append("onboarding3_old_image", resget?.data?.data?.onboarding3_image?.old_image ?? "");
      apiMethod(`appcontent/onboard`, formdata)
    }
  }

  useEffect(() => {
    if (res.data) {
      const { status, message } = res?.data
      if (status === "false") {
        toast.error(message);
      }
      else {
        toast.success(message);
      }
    }
  }, [res.data])

  if (resget.isLoading) return <SkeletonBoarding />
  const initialValues = {
    onboarding1_heading: resget?.data?.data?.onboarding1_heading,
    onboarding1_paragraph: resget?.data?.data?.onboarding1_paragraph,
    onboarding2_heading: resget?.data?.data?.onboarding2_heading,
    onboarding2_paragraph: resget?.data?.data?.onboarding2_paragraph,
    onboarding3_heading: resget?.data?.data?.onboarding3_heading,
    onboarding3_paragraph: resget?.data?.data?.onboarding3_paragraph,
  }
  return (
    <div className='Boarding pr-10 max-lg:pr-6'>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}  >
        <Form>
          <div className='bg-[#EFF4FD] p-6 rounded-3xl mb-3'>
            <div className="BoardingMain grid grid-cols-3 gap-3 max-lg:grid-cols-1">
              <div className="BoardingMainBOx">
               
                <div className="h4 text-[#7D8CA7] text-[1.1rem] ">Onboarding screen 01</div>
                <div className="BoardingMainBOx_ bg-[#DEE5F2] p-3 rounded-3xl mt-3">
                <label className="mb-1  mt-3 block text-[#7D8CA7] text-[.8rem]">Header image </label>
                <div className=' h-[10rem] relative' onDrop={(e) => handleDrop(e,"Onboarding1")}   >
                  {Onboarding1?.image ?
                    <img src={Onboarding1?.image || profile} className='w-full h-full rounded-3xl object-cover ' alt="" />
                    :
                    <div className='w-full h-full  rounded-3xl object-cover  bg-white'></div>
                  }
                  <div className="TeamBoxinput w-[3rem] h-[3rem] bg-[#C0CCE2] grid  place-items-center rounded-full absolute mx-auto right-0 left-0 top-[50%] transform translate-y-[-50%] cursor-pointer z-1">
                    <input type="file" onChange={(e) => handleFileUpload(e,"Onboarding1")} className='absolute inset-0 opacity-0 cursor-pointer' />
                    <img src={camera} alt="camera" className='cursor-pointer w-full p-3' />
                  </div>
                </div>
                  <FormControl name="onboarding1_heading" label={"heading"} placeholder="Enter heading" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                  <FormControl name="onboarding1_paragraph" label={"Sub Heading"} placeholder="Sub Heading" className="outline-none w-full h-[6rem] px-5 py-3 rounded-xl resize-none" control="textarea2" />
                </div>
              </div>
              <div className="BoardingMainBOx">
                <div className="h4 text-[#7D8CA7] text-[1.1rem] ">Onboarding screen 02</div>
                <div className="BoardingMainBOx_ bg-[#DEE5F2] p-3 rounded-3xl mt-3">
                <label className="mb-1  mt-3 block text-[#7D8CA7] text-[.8rem]">Header image </label>
                <div className=' h-[10rem] relative'onDrop={(e) => handleDrop(e,"Onboarding2")}   >
                  {Onboarding2?.image ?
                    <img src={Onboarding2?.image || profile} className='w-full h-full rounded-3xl object-cover ' alt="" />
                    :
                    <div className='w-full h-full  rounded-3xl object-cover  bg-white'></div>
                  }
                  <div className="TeamBoxinput w-[3rem] h-[3rem] bg-[#C0CCE2] grid  place-items-center rounded-full absolute mx-auto right-0 left-0 top-[50%] transform translate-y-[-50%] cursor-pointer z-1">
                    <input type="file" onChange={(e) => handleFileUpload(e,"Onboarding2")} className='absolute inset-0 opacity-0 cursor-pointer' />
                    <img src={camera} alt="camera" className='cursor-pointer w-full p-3' />
                  </div>
                </div>
                  <FormControl name="onboarding2_heading" label={"heading"} placeholder="Enter heading" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                  <FormControl name="onboarding2_paragraph" label={"Sub Heading"} placeholder="Sub Heading" className="outline-none w-full h-[6rem] px-5 py-3 rounded-xl resize-none" control="textarea2" />
                </div>
              </div>
              <div className="BoardingMainBOx">
                <div className="h4 text-[#7D8CA7] text-[1.1rem] ">Onboarding screen 03</div>
                <div className="BoardingMainBOx_ bg-[#DEE5F2] p-3 rounded-3xl mt-3">
                <label className="mb-1  mt-3 block text-[#7D8CA7] text-[.8rem]">Header image </label>
                <div className=' h-[10rem] relative'onDrop={(e) => handleDrop(e,"Onboarding3")}   >
                  {Onboarding3?.image ?
                    <img src={Onboarding3?.image || profile} className='w-full h-full rounded-3xl object-cover ' alt="" />
                    :
                    <div className='w-full h-full  rounded-3xl object-cover  bg-white'></div>
                  }
                  <div className="TeamBoxinput w-[3rem] h-[3rem] bg-[#C0CCE2] grid  place-items-center rounded-full absolute mx-auto right-0 left-0 top-[50%] transform translate-y-[-50%] cursor-pointer z-1">
                    <input type="file" onChange={(e) => handleFileUpload(e,"Onboarding3")} className='absolute inset-0 opacity-0 cursor-pointer' />
                    <img src={camera} alt="camera" className='cursor-pointer w-full p-3' />
                  </div>
                </div>
                  <FormControl name="onboarding3_heading" label={"heading"} placeholder="Enter heading" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                  <FormControl name="onboarding3_paragraph" label={"Sub Heading"} placeholder="Sub Heading" className="outline-none w-full h-[6rem] px-5 py-3 rounded-xl resize-none" control="textarea2" />
                </div>
              </div>
            </div>
          </div>
          <SubmitButton
            props={{
              class: "btn bg-secondary text-white px-12 ml-auto uppercase   py-3 rounded-full w-100 block mt-8 max-lg:mt-4 submit hover:bg-primary transition-all duration-300",
              text: "Update",
            }}
            buttonLoading={res.isLoading}
          />
        </Form>
      </Formik>
    </div>
  )
}

export default Boarding