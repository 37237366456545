import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import useDelete from "../customHooks/useDelete";
import { toast } from "react-toastify";
const CaseCard = ({data,alldata,deleted}) => {
   const {id,client_id,client_name,case_number,case_title,teams,secretaries} = data

   const [resDelete, apiMethodDelete] = useDelete()
    const handleDelete = () => {
        swal({
            title: "Are you sure?",
            text: "Are you sure that you want to delete?",
            buttons: true,
            icon: "warning",
            dangerMode: true,
        }).then((willDelete) => {
            if (willDelete) {
                swal("Successfully Delete", "", "success");
                apiMethodDelete(`caseManagements/delete/${id}`)
            }
        });
    };

    useEffect(() => {
        if(resDelete.data) {
          const {message} = resDelete.data
          const update = alldata.filter((item) => item.id !== id)
          deleted(update)
          toast.success(message);
        }
      }, [resDelete.data])
    return (
        <div className="bookingBox cursor-pointer bg-[#EFF4FD] p-5 rounded-xl relative">
            <div
                className="closeButton cursor-pointer absolute bg-[#FFCEDA] w-[2rem] h-[2rem] p-[.6rem] top-[.7rem] right-[.7rem] grid place-items-center rounded-[.7rem] z-10"
                onClick={handleDelete}
            >
                <svg
                    className="w-full h-full"
                    width="17"
                    height="17"
                    viewBox="0 0 17 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M15.8612 1.34766L0.951172 16.2567M15.8612 16.2577L0.951172 1.34863"
                        stroke="#ED2656"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </div>

            <Link to={`/case/edit/${id}`}>
                <div className="h2 text-[1rem] font-Mluvka flex items-center gap-1">
                    <span className="">
                       {client_name}
                    </span>
                    <span className="bg-primary p-1 text-[1rem] rounded-xl">
                       {case_number}
                    </span>
                </div>
               <h1 className="mt-2 text-[1.5rem] font-Mluvka">{case_title}</h1>
               
                <div className="Lawyer my-4 flex items-center gap-2 ">
                    <span className="text-[#95ACD5] font-Mluvka text-[.8rem]">
                        Team
                    </span>
                    <div className="w-full h-[.1rem] bg-[#C9D4E9]"></div>
                </div>

                <div className="dpartCardProfile flex mt-1">    
                {Array.isArray(teams) && teams.map((item, index) => {
                                const {lowyer_image,id} = item
                                return (
                                    <div className="dpartCardProfile-img" key={index}>
                                        <img className={`w-[2.4rem] h-[2.4rem] min-w-[2.4rem] min-h-[2.4rem] rounded-full object-cover border-2 border-[#fff] ${index !== 0 && "-ml-3"}`} src={lowyer_image} alt={id} />
                                    </div>
                                )
                            })}
                  
                    </div>
                <div className="Lawyer my-4 flex items-center gap-2 ">
                    <span className="text-[#95ACD5] font-Mluvka text-[.8rem]">
                    Legal Secretary
                    </span>
                    <div className="w-full h-[.1rem] bg-[#C9D4E9]"></div>
                </div>

                <div className="dpartCardProfile flex mt-1">    
                {Array.isArray(secretaries) && secretaries.map((item, index) => {
                                const {legal_secretary_image,id} = item
                                return (
                                    <div className="dpartCardProfile-img" key={index}>
                                        <img className={`w-[2.4rem] h-[2.4rem] min-w-[2.4rem] min-h-[2.4rem] rounded-full object-cover border-2 border-[#fff] ${index !== 0 && "-ml-3"}`} src={legal_secretary_image} alt={id} />
                                    </div>
                                )
                            })}
                  
                    </div>
            </Link>
        </div>
    );
};

export default CaseCard;
