import React from 'react'
import Coffee from "../dist/webImages/coffee.svg"
import calendar from "../dist/webImages/calendar.svg"
import clock from "../dist/webImages/wall-clock.svg"
import noimg from '../dist/webImages/no_image.jpg'
import dayjs from 'dayjs';

const BookingsCard = ({data,modelDataUpdate,modelStatusUpdate}) => {
    const {id,beverage,booking_status,meeting_purpose,client_name,client_email,meeting_date,consultant_image,consultant_name,consultant_designation,number_of_attendees,time_slot} = data
    const handleOpenModel = () => {
        modelStatusUpdate(true)
        modelDataUpdate(data)
    }

    const formattedDate = dayjs(meeting_date).format('MMMM D, YYYY');

  return (
    <div className="bookingBox cursor-pointer bg-[#EFF4FD] p-5 rounded-xl" onClick={handleOpenModel}>
                <ul className='list'>
                    <li className='flex items-center justify-between gap-2'>
                        <div className='h2 text-[1.5rem] font-Mluvka flex items-center gap-1'> <span className='bg-primary p-1 text-[1rem] rounded-xl'>#{id}</span> {client_name}</div> 
                        {
                         booking_status === "completed" ?
                        <span className='p-2 text-white rounded-[.5rem] bg-[green] text-[.8rem]'>Completed</span>
                        :
                        booking_status === "cancelled" ?
                        <span className='p-2 text-white rounded-[.5rem] bg-[red] text-[.8rem]'>Cancelled</span>
                        :
                        <span className='p-2 text-white rounded-[.5rem] bg-[#B7903B] text-[.8rem]'>Pending</span>
                       }
                    </li>
                </ul>
                <div className="h3 mb-3">{client_email}</div>
                        {/* <div className='flex items-center gap-1'><img src={Coffee} alt="Coffee" /><span className='font-Mluvka text-[.8rem]'>{beverage}</span></div> */}
                      

                <div className='calender my-4 flex items-center gap-2'>
                    <img src={calendar} className='w-[1.5rem]' alt="calender" />
                    <span className='font-MluvkaBold text-[.9rem]'>{formattedDate}</span>
                </div>
                <div className='calender my-4 flex items-center gap-2'>
                    <img src={clock} className='w-[1.5rem]' alt="calender" />
                    <span className='font-MluvkaBold text-[.9rem]'>{time_slot}</span>
                </div>

                <div className='evening mb-4'>
                    {/* <div className="h4 font-MluvkaBold mb-2">Number of Person</div> */}
                    <ul className='list flex items-center gap-3'>
                        <li className='flex items-center gap-2 bg-white py-3 px-5 rounded-full'>
<svg className="svg-icon w-[1rem] fill-[#B7903B]" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M78.22291438 968.30107625c0 14.21935406 9.47956969 23.69892375 23.69892374 23.69892375s23.69892375-9.47956969 23.69892375-23.69892375c0-213.33036656 173.03552063-386.36588719 386.36588625-386.36588625s386.37923813 173.03552063 386.37923907 386.36588625c0 14.21935406 9.47956969 23.69892375 23.69892281 23.69892375s23.69892375-9.47956969 23.69892375-23.69892375c0-196.74779531-132.74067469-362.66696344-310.51597969-417.19451437 90.06926062-45.03463031 151.713165-137.52051375 151.713165-244.14564282C786.96091906 155.261105 663.69981406 32 511.98664813 32S237.03908094 155.261105 237.03908094 306.96091906c0 106.66518281 61.6305525 199.1110125 151.69981406 244.14564281C208.60037281 603.25754469 78.22291438 769.17671281 78.22291438 968.30107625z m206.22736499-663.75678c0-125.62432219 101.92539844-227.56307156 227.54972063-227.56307156s227.56307156 101.99215594 227.56307156 227.56307156S637.651025 532.1474225 512 532.1474225s-227.54972063-101.92539844-227.54972063-227.60312625z m0 0" fill="" /></svg>
                              <span className=' text-[.8rem] font-MluvkaBold'>{number_of_attendees}</span></li>
                    </ul>
                </div>
                <div className='evening mb-4'>
                    <div className="h4 text-[.8rem] font-MluvkaBold mb-1">Purpose Of Meeting</div>
                    <ul className='list flex items-center gap-3'>
                        <li className='flex items-center gap-2 bg-white py-1 px-5 rounded-lg'>
                            <p>{meeting_purpose}</p>
                        </li>
                    </ul>
                </div>
               
             {consultant_name &&       <>
                <div className='Lawyer my-4 flex items-center gap-2 '>
                    <span className='text-[#95ACD5] font-Mluvka text-[.8rem]'>Team</span>
                    <div className='w-full h-[.1rem] bg-[#C9D4E9]'></div>
                </div>

                <div className='bookingprofile flex items-center gap-3'>
                    <div className="bookingprofile__img  grid place-items-center bg-[#C9D3E4] rounded-xl">
                        <img src={consultant_image} onError={(e) => { e.target.src = noimg }} className='rounded-lg w-[3.375rem] h-[3.375rem] object-cover' alt="bookingProfile" />
                    </div>
                    <div className="bookingprofile__txt">
                        <div className="h5 font-Mluvka capitalize leading-[1] mb-1">{consultant_name}</div>
                        <div className="h4 text-[.8rem]">{consultant_designation}</div>
                    </div>
                </div>
                    </>}
            </div>
  )
}

export default BookingsCard
