import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import back from "../dist/webImages/back.svg";
import { Select } from 'antd';
import useFetch from '../customHooks/useFetch';
import useGet from '../customHooks/useGet';
import usePost from '../customHooks/usePost';
import swal from "sweetalert";
import { toast } from "react-toastify";
const { Option } = Select;




const CreateCaseUpdate = () => {
  const navigate = useNavigate();
  const { loading:loading2, data:data2 } = useFetch(`caseManagements/1000`);
  const [resget, apiMethodGet] = useGet()
  const [caseUpdate, setCaseUpdate] = useState("")
  const [caseList, setCaseList] = useState("");
  const [datas, setDatas] = useState("")

  const handleChange = (value) => {
    apiMethodGet(`caseManagements/singleDetail/${value}`)
    setCaseList(value)
  }


  useEffect(() => {
   if(resget?.data) {
    setDatas(resget?.data?.data)
   }
  }, [resget?.data])


  const [res, apiMethod] = usePost();


  const handleSubmit = () => {
    let formdata = new FormData();
    formdata.append("case_id", caseList);
    formdata.append("message", caseUpdate);
    let checkerRequried = [];

    if (!caseList) {
      checkerRequried.push("Case List Image");
    }
    if (!caseUpdate) {
      checkerRequried.push("Case Description");
    }
    if (checkerRequried.length > 0) {
      swal({
        title: "Required Fields are empty! Please fill and try again",
        text: checkerRequried.join(","),
        icon: "error",
        dangerMode: true,
      });
    }
    else {
    apiMethod(`caseManagements/requestCaseUpdate`,formdata)
    }
}
  

useEffect(() => {
  if (res.data) {
    const { status, message } = res?.data
    if (status === "false") {
      toast.error(message);
    }
    else {
      navigate("/case/update")
      toast.success(message);
    }
  }
}, [res.data])

  
  if(loading2) return ""
  return (
    <div className="CreateCaseUpdate pr-10">
          <Link to={"/case/update"} className="back flex items-center mb-5 gap-2">
            <img src={back} className='w-[2rem]' alt="" />
            <span className='text-[1.4rem] font-MluvkaBold'>Create Update</span>
            </Link>
        <div className='bg-[#EFF4FD] p-5 rounded-xl'>
        <label htmlFor="Case List" className="mb-1 block text-[#7D8CA7] text-[.8rem]">Case List</label>
        <Select value={caseList}  onChange={handleChange} name="id" className="w-full rounded-xl h-[2.8rem] px-0 outline-0 capitalize appearance-none max-lg:text-[.9rem]"  placeholder="Case List">
  {Array.isArray(data2?.data) && data2?.data.map((item, index) => {
    const { id, case_title, case_number } = item;
    return (
      <Option value={id} key={index}>
        <div className="space-x-2 flex">
          <div> {case_title}</div> &nbsp; (<div className="">{case_number}</div> )
        </div>
      </Option>
    );
  })}
</Select>


{
  caseList &&
  <div className="bookingBox mt-4 cursor-pointer bg-[#dee4f0] p-5 rounded-xl relative">
                <div className="h2 text-[1rem] font-Mluvka flex items-center gap-1">
                    <span className="">
                       {datas?.client_name}
                    </span>
                    <span className="bg-primary p-1 text-[1rem] rounded-xl">
                       {datas?.case_number}
                    </span>
                </div>
               <h1 className="mt-2 text-[1.5rem] font-Mluvka">{datas?.case_title}</h1>
               
                <div className="Lawyer my-4 flex items-center gap-2 ">
                    <span className="text-[#95ACD5] font-Mluvka text-[.8rem]">
                        Teams
                    </span>
                    <div className="w-full h-[.1rem] bg-[#C9D4E9]"></div>
                </div>
                <div className="dpartCardProfile flex mt-1">    
                {Array.isArray(datas?.teams) && datas?.teams.map((item, index) => {
                                const {lowyer_image,id} = item
                                return (
                                    <div className="dpartCardProfile-img" key={index}>
                                        <img className={`w-[2.4rem] h-[2.4rem] min-w-[2.4rem] min-h-[2.4rem] rounded-full object-cover border-2 border-[#fff] ${index !== 0 && "-ml-3"}`} src={lowyer_image} alt={id} />
                                    </div>
                                )
                            })}
                  
                    </div>
                <div className="Lawyer my-4 flex items-center gap-2 ">
                    <span className="text-[#95ACD5] font-Mluvka text-[.8rem]">
                    Legal Secretary
                    </span>
                    <div className="w-full h-[.1rem] bg-[#C9D4E9]"></div>
                </div>

                <div className="dpartCardProfile flex mt-1">    
                {Array.isArray(datas?.secretaries) && datas?.secretaries.map((item, index) => {
                                const {legal_secretary_image,id} = item
                                return (
                                    <div className="dpartCardProfile-img" key={index}>
                                        <img className={`w-[2.4rem] h-[2.4rem] min-w-[2.4rem] min-h-[2.4rem] rounded-full object-cover border-2 border-[#fff] ${index !== 0 && "-ml-3"}`} src={legal_secretary_image} alt={id} />
                                    </div>
                                )
                            })}
                  
                    </div>
        </div>
}


<div className='mt-3'>
<label htmlFor="Case List" className="mb-1 block text-[#7D8CA7] text-[.8rem]">Case Description</label>
 <textarea onChange={(e) => setCaseUpdate(e.target.value)} placeholder='Case Description' className='w-full border border-[#ddd] rounded-xl h-[5rem] p-2 outline-none' name="" id=""></textarea>
</div>




<button  onClick={handleSubmit} className='font-Mluvka w-fit mt-4 ml-auto block py-3 px-5 uppercase text-white bg-secondary rounded-full'>{res.isLoading ? "loading" : "Submit"}</button>

        </div>
    </div>
  )
}

export default CreateCaseUpdate