import { Form, Formik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import FormControl from '../../components/form/FormControl'
import swal from "sweetalert";
import camera from "../../dist/webImages/camera.svg"
import profile from "../../dist/webImages/profile2.png"
import plus from '../../dist/webImages/plus.svg'
import { Link } from 'react-router-dom';
import SubmitButton from '../../components/SubmitButton';
import SkeletonAboutUs from './SkeletonAboutUs';
import usePost from '../../customHooks/usePost';
import { MainLanguageContext } from '../../context/MainLanguageContext';
import useGet from '../../customHooks/useGet';
import { toast } from 'react-toastify';
const AboutUs = () => {
    const { mainLanguage } = useContext(MainLanguageContext); 
    const [loading, setLoading] = useState(true)
    const [resget, apiMethodGet] = useGet()
    useEffect(() => {
        if (mainLanguage) {
            setLoading(true);
            apiMethodGet(`webContents/aboutUs/${mainLanguage}`);
        }
    }, [mainLanguage]);

    const [datas, setDatas] = useState({
        sec_three:{},
        sec_four:{},
        sec_five:{},
        sec_six:{}
    })
    const [imgPath, setImgPath] = useState("");
    const [imgValue, setImgValue] = useState("");
    const [imgPath3, setImgPath3] = useState("");
    const [imgValue3, setImgValue3] = useState("");
    useEffect(() => {
        if(!resget.isLoading) {
            setLoading(false)
            setDatas(resget?.data?.data)
            setImgPath(resget?.data?.data?.sec_two_image)
            setImgPath3(resget?.data?.data?.sec_three_image)
        }
       }, [resget.data])


    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            let path = (window.URL || window.webkitURL).createObjectURL(file);
            setImgPath(path);
            setImgValue(file);
        }
    }
    const handleDrop = (e) => {
        e.preventDefault();
        if (e.dataTransfer.files[0] && e.dataTransfer.files[0]?.type?.includes("image")) {
            let path = (window.URL || window.webkitURL).createObjectURL(e.dataTransfer.files[0]);
            setImgPath(path);
            const file = e.dataTransfer.files[0];
            setImgValue(file);
        }
        else {
            swal("Only use Image", "", "warning");
        }
    };
    const handleFileUpload3 = (e) => {
        const file = e.target.files[0];
        if (file) {
            let path = (window.URL || window.webkitURL).createObjectURL(file);
            setImgPath3(path);
            setImgValue3(file);
        }
    }
    const handleDrop3 = (e) => {
        e.preventDefault();
        if (e.dataTransfer.files[0] && e.dataTransfer.files[0]?.type?.includes("image")) {
            let path = (window.URL || window.webkitURL).createObjectURL(e.dataTransfer.files[0]);
            setImgPath3(path);
            const file = e.dataTransfer.files[0];
            setImgValue3(file);
        }
        else {
            swal("Only use Image", "", "warning");
        }
    };

    const handleInputChange = (e, section, index) => {
        const { name, value } = e.target;
        setDatas(prevService => ({
            ...prevService,
            [section]: prevService[section].map((item,index2) =>
                index2 === index ? { ...item, [name]: value } : item
            )
        }));
    }
    const handleSec3Add = () => {
        const newServices = {
            year: "",
            heading_one: "",
            description: "",
        };

        setDatas((prevState) => ({
            ...prevState,
            sec_three: [...prevState.sec_three, newServices]
        }));
    }

    const handleSec4Add = () => {
        const newServices = {
            heading: "",
            description: "",
            image: "",
            imgValue: "",
            btn_label: "",
        };

        setDatas((prevState) => ({
            ...prevState,
            sec_four: [...prevState.sec_four, newServices]
        }));
    }
    const handleSec5Add = () => {
        const newServices = {
            image: "",
            imgValue: "",
        };

        setDatas((prevState) => ({
            ...prevState,
            sec_five: [...prevState.sec_five, newServices]
        }));
    }

    const handleSec6Add = () => {
        const newServices = {
            heading: "",
            description: "",
            image: "",
            imgValue: "",
            btn_label: "",
        };

        setDatas((prevState) => ({
            ...prevState,
            sec_six: [...prevState.sec_six, newServices]
        }));
    }
    const handleDrop2 = (e, section, index) => {
        if (e.dataTransfer.files[0] && e.dataTransfer.files[0]?.type?.includes("image")) {
            let path = (window.URL || window.webkitURL).createObjectURL(e.dataTransfer.files[0]);
            const file = e.dataTransfer.files[0];
            setDatas(prevService => ({
                ...prevService,
                [section]: prevService[section].map((item,index2) =>
                    index2 === index ? { ...item, "image": path, "imgValue": file } : item
                )
            }));
        } else {
            swal("Only use Image", "", "warning");
        }
    };
    const handleFileUpload2 = (e, section, index) => {
        const file = e.target.files[0];
        if (file) {
            let path = (window.URL || window.webkitURL).createObjectURL(file);
            setDatas(prevService => ({
                ...prevService,
                [section]: prevService[section].map((item,index2) =>
                    index2 === index ? { ...item, "image": path, "imgValue": file } : item
                )
            }));
        }
    }
    const handleDelete3 = (index) => {
        swal({
            title: "Are you sure?",
            text: "you want to remove",
            buttons: true,
            icon: "warning",
            dangerMode: true,
        })
            .then(willDelete => {
                if (willDelete) {
                    setDatas((prevState) => ({
                        ...prevState,
                        sec_three: prevState.sec_three.filter((faq,i) => i !== index)
                    }));
                    swal("Successfully Delete", "", "success");
                }

            });
    }
    const handleDelete4 = (index) => {
        swal({
            title: "Are you sure?",
            buttons: true,
            icon: "warning",
            dangerMode: true,
        })
            .then(willDelete => {
                if (willDelete) {
                    setDatas((prevState) => ({
                        ...prevState,
                        sec_four: prevState.sec_four.filter((faq,i) => i !== index)
                    }));
                    swal("Successfully Delete", "", "success");
                }

            });
    }
    const handleDelete5 = (index) => {
        swal({
                       text: "you want to remove",
            buttons: true,
            icon: "warning",
            dangerMode: true,
        })
            .then(willDelete => {
                if (willDelete) {
                    setDatas((prevState) => ({
                        ...prevState,
                        sec_five: prevState.sec_five.filter((faq,i) => i !== index)
                    }));
                    swal("Successfully Delete", "", "success");
                }

            });
    }
    const handleDelete6 = (index) => {
        swal({
                       text: "you want to remove",
            buttons: true,
            icon: "warning",
            dangerMode: true,
        })
            .then(willDelete => {
                if (willDelete) {
                    setDatas((prevState) => ({
                        ...prevState,
                        sec_six: prevState.sec_six.filter((faq,i) => i !== index)
                    }));
                    swal("Successfully Delete", "", "success");
                }

            });
    }
    const [res, apiMethod] = usePost()

  const requireFeild = ["meta_tag", "meta_description","main_heading"];
    const handleSubmit = (values) => {
        let formdata = new FormData();
        let requireFeildSwal = {
            meta_tag: "Meta Tag",
            'meta_description': "Meta Description",
            'main_heading': "Main Heading",
          };
          let checkerRequried = [];
        for (const item in values) {
            if (requireFeild.includes(item) && values[item] === "") {
                checkerRequried.push(requireFeildSwal[item]);
              }
        }
        if (checkerRequried.length > 0) {
            swal({
              title: "Required Fields are empty! Please fill and try again",
              text: checkerRequried.join(","),
              icon: "error",
              dangerMode: true,
            });
          }
          else {
        formdata.append(`translation[meta_tag]`,values["meta_tag"] ?? "")
        formdata.append(`translation[meta_description]`,values["meta_description"] ?? "")
        formdata.append(`translation[schema_code]`,values["schema_code"] ?? "")
        formdata.append(`translation[main_heading]`,values["main_heading"] ?? "")
        formdata.append(`translation[sec_two_heading_two]`,values["sec_two_heading_two"] ?? "")
        formdata.append(`translation[sec_two_para_left]`,values["sec_two_para_left"] ?? "")
        formdata.append(`translation[sec_two_para_right]`,values["sec_two_para_right"] ?? "")
        formdata.append(`translation[sec_three_heading_one]`,values["sec_three_heading_one"] ?? "")
        formdata.append(`translation[sec_five_heading_one]`,values["sec_five_heading_one"] ?? "")
        formdata.append(`translation[sec_five_heading_two]`,values["sec_five_heading_two"] ?? "")
        formdata.append(`translation[sec_five_description]`,values["sec_five_description"] ?? "")
        formdata.append(`translation[sec_six_heading]`,values["sec_six_heading"] ?? "")
        formdata.append(`translation[sec_six_discription]`,values["sec_six_discription"] ?? "")
        formdata.append("sec_two_image",imgValue ?? "")
        formdata.append("sec_three_image",imgValue3 ?? "")
        if(sec_three.length > 0) {
        for (let index = 0; index < sec_three.length; index++) {
            formdata.append(`translation[sec_three][${index}][heading_one]`,sec_three[index].heading_one ?? "")
            formdata.append(`translation[sec_three][${index}][description]`,sec_three[index].description ?? "")
            formdata.append(`translation[sec_three][${index}][year]`,sec_three[index].year ?? "")
        }
    }
    if(sec_four.length > 0) {
        for (let index = 0; index < sec_four.length; index++) {
            formdata.append(`translation[sec_four][${index}][heading]`,sec_four[index].heading ?? "")
            formdata.append(`translation[sec_four][${index}][description]`,sec_four[index].description ?? "")
            formdata.append(`translation[sec_four][${index}][image]`,sec_four[index].imgValue ?? sec_four[index].image ?? "")
            formdata.append(`translation[sec_four][${index}][btn_label]`,sec_four[index].btn_label ?? "")
        }
    }
        if(sec_five.length > 0) {
            for (let index = 0; index < sec_five.length; index++) {
                formdata.append(`translation[sec_five][${index}][image]`, sec_five[index].imgValue ?? null)
                formdata.append(`translation[sec_five][${index}][old_image]`, sec_five[index].old_image ?? null)
            }
        }
        if(sec_six.length > 0) {
        for (let index = 0; index < sec_six.length; index++) {
            formdata.append(`translation[sec_six][${index}][heading]`,sec_six[index].heading ?? "")
            formdata.append(`translation[sec_six][${index}][description]`,sec_six[index].description ?? "")
            formdata.append(`translation[sec_six][${index}][image]`,sec_six[index].imgValue ?? "" )
            formdata.append(`translation[sec_six][${index}][btn_label]`,sec_six[index].btn_label ?? "")
            
        }
    }
        apiMethod(`webContents/aboutUs/${mainLanguage}`,formdata)
    }
        // console.log(...formdata)

    }
    useEffect(() => {
        if (res.data) {
          const { status, message } = res?.data
          if (status === "false") {
            toast.error(message);
          }
          else {
            toast.success(message);
          }
        }
      }, [res.data])

    if (resget.isLoading || loading || !datas) return <SkeletonAboutUs />
    const { sec_three, sec_four, sec_five, sec_six } = datas
    const {
        meta_tag,
        meta_description,
        schema_code,
        main_heading,
        sec_two_heading_one,
        sec_two_para_left,
        sec_two_heading_two,
        sec_two_para_right,
        sec_three_heading_one,
        sec_five_heading_one,
        sec_five_heading_two,
        sec_five_description,
        sec_six_heading,
        sec_six_discription
    } = resget?.data?.data
    const initialValues = {
        meta_tag: meta_tag ?? "",
        meta_description: meta_description ?? "",
        schema_code: schema_code ?? "",
        main_heading: main_heading ?? "",
        sec_two_heading_one: sec_two_heading_one ?? "",
        sec_two_para_left: sec_two_para_left ?? "",
        sec_two_heading_two: sec_two_heading_two ?? "",
        sec_two_para_right: sec_two_para_right ?? "",
        sec_three_heading_one: sec_three_heading_one ?? "",
        sec_five_heading_one: sec_five_heading_one ?? "",
        sec_five_heading_two: sec_five_heading_two ?? "",
        sec_five_description: sec_five_description ?? "",
        sec_six_heading: sec_six_heading ?? "",
        sec_six_discription: sec_six_discription ?? "",
    }
    return (
        <div className='aboutPage pr-10 max-lg:pr-6'>
            <Formik initialValues={initialValues} onSubmit={handleSubmit}  >
                <Form>
                    <div className='bg-[#EFF4FD] p-6 rounded-3xl mb-3'>
                        <FormControl name="meta_tag" label={"Meta Title"} placeholder="Enter Meta Title" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                        <div className='grid grid-cols-2 gap-3 max-lg:grid-cols-1 max-lg:gap-0'>
                            <FormControl name="meta_description" label={"Meta Description"} placeholder="Enter Meta Description" className="outline-none w-full h-[10rem] px-5 py-3 rounded-xl resize-none" control="textarea2" />
                            <FormControl name="schema_code" label={"Scheme Code"} placeholder="Scheme Code" className="outline-none w-full h-[10rem] px-5 py-3 rounded-xl resize-none" control="textarea2" />
                        </div>
                    </div>
                    <div className='bg-[#EFF4FD] p-6 rounded-3xl mb-3'>
                    <div className="h4 text-[#7D8CA7] text-[1.1rem] ">Section 1</div>
                    <FormControl name="main_heading" label={"Heading"} placeholder="Enter Heading" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                    </div>
                    <div className='bg-[#EFF4FD] p-6 rounded-3xl mb-3'>
                        <div className="h4 text-[#7D8CA7] text-[1.1rem] ">Section 2</div>
                        <div className="Section2Main grid grid-cols-3 gap-3 max-lg:grid-cols-1">
                            <div className="Section2Main__left">
                                <FormControl name="sec_two_heading_one" label={"Heading"} placeholder="Enter Heading" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                                <FormControl name="sec_two_para_left" label={"Paragraph"} placeholder="Paragraph" className="outline-none w-full h-[15rem] px-5 py-3 rounded-xl resize-none" control="textarea2" />
                                <FormControl name="sec_two_heading_two" label={"Heading 2"} placeholder="Enter Heading 2" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                            </div>
                            <div className="section2Main__right">
                                <div>
                                    <label className="mb-1  mt-3 block text-[#7D8CA7] text-[.8rem]">Image</label>
                                    <div className=' h-[28rem] relative' onDrop={handleDrop}   >
                                        {imgPath ?
                                            <img src={imgPath || profile} className='w-full h-full rounded-3xl object-cover ' alt="" />
                                            :
                                            <div className='w-full h-full  rounded-3xl object-cover  bg-white'></div>
                                        }
                                        <div className="TeamBoxinput w-[3rem] h-[3rem] bg-[#C0CCE2] grid  place-items-center rounded-full absolute mx-auto right-0 left-0 top-[50%] transform translate-y-[-50%] cursor-pointer z-1">
                                            <input type="file" onChange={handleFileUpload} className='absolute inset-0 opacity-0 cursor-pointer' />
                                            <img src={camera} alt="camera" className='cursor-pointer w-full p-3' />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="Section2Main__left">
                                <FormControl name="sec_two_para_right" label={"Paragraph"} placeholder="Paragraph" className="outline-none w-full h-[28rem] px-5 py-3 rounded-xl resize-none" control="textarea2" />
                            </div>
                        </div>
                    </div>
                    <div className='bg-[#EFF4FD] p-6 rounded-3xl mb-3'>
                        <div className='flex justify-between'>
                            <div className="h4 text-[#7D8CA7] text-[1.1rem] ">Section 3</div>
                            <Link className='bg-[#d9dcf8] py-3 px-6 rounded-full flex items-center gap-2 cursor-pointer' onClick={handleSec3Add} >
                                <img src={plus} alt="plus" />
                                <span className='font-MluvkaBold text-secondary capitalize'>Add new</span>
                            </Link>
                        </div>
                        <div className='w-[15rem] max-lg:w-full'>
                                    <label className="mb-1  mt-3 block text-[#7D8CA7] text-[.8rem]">Image</label>
                                    <div className=' h-[15rem] relative' onDrop={handleDrop3}   >
                                        {imgPath ?
                                            <img src={imgPath3 || profile} className='w-full h-full rounded-3xl object-cover ' alt="" />
                                            :
                                            <div className='w-full h-full  rounded-3xl object-cover  bg-white'></div>
                                        }
                                        <div className="TeamBoxinput w-[3rem] h-[3rem] bg-[#C0CCE2] grid  place-items-center rounded-full absolute mx-auto right-0 left-0 top-[50%] transform translate-y-[-50%] cursor-pointer z-1">
                                            <input type="file" onChange={handleFileUpload3} className='absolute inset-0 opacity-0 cursor-pointer' />
                                            <img src={camera} alt="camera" className='cursor-pointer w-full p-3' />
                                        </div>
                                    </div>
                                </div>
                        <FormControl name="sec_three_heading_one" label={"Heading 1"} placeholder="Enter Heading 1" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                       
                        
                        <div className="section3Main grid grid-cols-3 gap-3 mt-4 max-lg:grid-cols-1">
                            {
                                Array.isArray(sec_three) && sec_three.map((item, index) => {
                                    const {  heading_one, description, year } = item
                                    return (
                                        <div className="section3MainBox relative bg-[#DEE5F2] p-4 rounded-lg" key={index}>
                                            <div className='closeButton cursor-pointer absolute right-[-.5rem] top-[-.5rem] ml-auto bg-[#FFCEDA] w-[2rem] h-[2rem] p-[.6rem] grid place-items-center rounded-[.7rem] z-10'
                                                onClick={() => handleDelete3(index)}  >
                                                <svg className='w-full h-full' width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M15.8612 1.34766L0.951172 16.2567M15.8612 16.2577L0.951172 1.34863" stroke="#ED2656" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </div>
                                            <div className="inputBox w-full mt-3">
                                                <label htmlFor="" className="mb-1 text-[#7D8CA7] text-[.8rem] flex justify-between"><span>Year</span>  <span>{(index + 1) >= 10 ? (index + 1) : `0${(index + 1)}`}</span></label>
                                                <input type="number" name="year" placeholder="Enter Year" className="outline-none w-full h-[3rem] px-5 rounded-xl" value={year} onChange={(e) => handleInputChange(e, "sec_three", index)} />
                                            </div>
                                            <div className="inputBox w-full mt-3">
                                                <label htmlFor="" className="mb-1 block text-[#7D8CA7] text-[.8rem]">Heading 1</label>
                                                <input name="heading_one" placeholder="Enter Heading" className="outline-none w-full h-[3rem] px-5 rounded-xl" value={heading_one} onChange={(e) => handleInputChange(e, "sec_three", index)} />
                                            </div>
                                            <div className="inputBox w-full mt-3">
                                                <label htmlFor="" className="mb-1 block text-[#7D8CA7] text-[.8rem]">Paragraph</label>
                                                <textarea id="Paragraph" name="description" placeholder="Paragraph" className="outline-none w-full h-[10rem] px-5 py-3 rounded-xl resize-none" value={description} onChange={(e) => handleInputChange(e, "sec_three", index)}></textarea>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                        </div>
                    </div>

                    <div className='bg-[#EFF4FD] p-6 rounded-3xl mb-3'>
                        <div className='flex justify-between'>
                            <div className="h4 text-[#7D8CA7] text-[1.1rem] ">Section 4</div>
                            <Link className='bg-[#d9dcf8] py-3 px-6 rounded-full flex items-center gap-2 cursor-pointer' onClick={handleSec4Add} >
                                <img src={plus} alt="plus" />
                                <span className='font-MluvkaBold text-secondary capitalize'>Add new</span>
                            </Link>
                        </div>
                        <div className="section4Main grid grid-cols-3 gap-3 mt-4 max-lg:grid-cols-1">
                            {
                                 Array.isArray(sec_four) && sec_four.map((item, index) => {
                                    const {  heading, image, description, btn_label } = item
                                    return (
                                        <div className="section3MainBox bg-[#DEE5F2] p-4 rounded-lg relative" key={index}>
                                            <div className='closeButton cursor-pointer absolute right-[-.5rem] top-[-.5rem] ml-auto bg-[#FFCEDA] w-[2rem] h-[2rem] p-[.6rem] grid place-items-center rounded-[.7rem] z-10'
                                                onClick={() => handleDelete4(index)}  >
                                                <svg className='w-full h-full' width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M15.8612 1.34766L0.951172 16.2567M15.8612 16.2577L0.951172 1.34863" stroke="#ED2656" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </div>
                                            <div className="inputBox w-full mt-3">
                                                <label htmlFor="" className="mb-1 text-[#7D8CA7] text-[.8rem] flex justify-between"><span>Heading</span>  <span>{(index + 1) >= 10 ? (index + 1) : `0${(index + 1)}`}</span></label>
                                                <input name="heading" placeholder="Enter Heading" className="outline-none w-full h-[3rem] px-5 rounded-xl" value={heading} onChange={(e) => handleInputChange(e, "sec_four", index)} />
                                            </div>
                                            <div className="inputBox w-full mt-3">
                                                <label htmlFor="" className="mb-1 block text-[#7D8CA7] text-[.8rem]">Paragraph</label>
                                                <textarea id="Paragraph" name="description" placeholder="Paragraph" className="outline-none w-full h-[10rem] px-5 py-3 rounded-xl resize-none" value={description} onChange={(e) => handleInputChange(e, "sec_four", index)}></textarea>
                                            </div>
                                            <div>
                                                <label className="mb-1  mt-3 block text-[#7D8CA7] text-[.8rem]">Image</label>
                                                <div className=' h-[10rem] relative' onDrop={(e) => handleDrop2(e, "sec_four", index)}   >
                                                    {image ?
                                                        <img src={image || profile} className='w-full h-full rounded-3xl object-cover ' alt="" />
                                                        :
                                                        <div className='w-full h-full  rounded-3xl object-cover  bg-white'></div>
                                                    }
                                                    <div className="TeamBoxinput w-[3rem] h-[3rem] bg-[#C0CCE2] grid  place-items-center rounded-full absolute mx-auto right-0 left-0 top-[50%] transform translate-y-[-50%] cursor-pointer z-1">
                                                        <input type="file" onChange={(e) => handleFileUpload2(e, "sec_four", index)} className='absolute inset-0 opacity-0 cursor-pointer' />
                                                        <img src={camera} alt="camera" className='cursor-pointer w-full p-3' />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="inputBox w-full mt-3">
                                                <label htmlFor="" className="mb-1 block text-[#7D8CA7] text-[.8rem]">BTN</label>
                                                <input name="btn_label" placeholder="Enter label" className="outline-none w-full h-[3rem] px-5 rounded-xl" value={btn_label} onChange={(e) => handleInputChange(e, "sec_four", index)} />
                                            </div> */}
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>

                    <div className='bg-[#EFF4FD] p-6 rounded-3xl mb-3'>
                        <div className=''>
                            <div className="h4 text-[#7D8CA7] text-[1.1rem] ">Section 5</div>
                            <div className="section5Main grid grid-cols-[3fr,7fr] gap-3 max-lg:grid-cols-1">
                                <div className="section5Main__Left">
                                    <FormControl name="sec_five_heading_one" label={"Heading"} placeholder="Enter Heading" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                                    <FormControl name="sec_five_heading_two" label={"Heading 2"} placeholder="Enter Heading 2" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                                    <FormControl name="sec_five_description" label={"Paragraph"} placeholder="Enter Paragraph" className="outline-none w-full h-[10rem] px-5 py-3 rounded-xl resize-none" control="textarea2" />
                                </div>
                                <div className="section5Main__RIght">
                                    <div className="mb-1 block text-[#7D8CA7] text-[.8rem]">Logos</div>
                                    <div className="sec5Main grid grid-cols-5 gap-3 max-lg:grid-cols-3">
                                        {
                                       Array.isArray(sec_five) && sec_five.map((item,index) => {
                                                const {  image } = item
                                                return (
                                                    <div className="sec5MainBox relative" key={index}>
                                                        <div className='closeButton cursor-pointer absolute right-[-.5rem] top-[-.5rem] ml-auto bg-[#FFCEDA] w-[2rem] h-[2rem] p-[.6rem] grid place-items-center rounded-[.7rem] z-10'
                                                            onClick={() => handleDelete5(index)}  >
                                                            <svg className='w-full h-full' width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M15.8612 1.34766L0.951172 16.2567M15.8612 16.2577L0.951172 1.34863" stroke="#ED2656" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                            </svg>
                                                        </div>
                                                        <div>
                                                            <div className=' h-[10rem] relative' onDrop={(e) => handleDrop2(e, "sec_five", index)}   >
                                                                {image ?
                                                                    <img src={image || profile} className='w-full h-full rounded-3xl object-cover ' alt="" />
                                                                    :
                                                                    <div className='w-full h-full  rounded-3xl object-cover  bg-white'></div>
                                                                }
                                                                <div className="TeamBoxinput w-[3rem] h-[3rem] bg-[#C0CCE2] grid  place-items-center rounded-full absolute mx-auto right-0 left-0 top-[50%] transform translate-y-[-50%] cursor-pointer z-1">
                                                                    <input type="file" onChange={(e) => handleFileUpload2(e, "sec_five", index)} className='absolute inset-0 opacity-0 cursor-pointer' />
                                                                    <img src={camera} alt="camera" className='cursor-pointer w-full p-3' />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <Link className='bg-[#d9dcf8] w-fit mt-3 py-3 px-6 rounded-full flex items-center gap-2 cursor-pointer' onClick={handleSec5Add} >
                                        <img src={plus} alt="plus" />
                                        <span className='font-MluvkaBold text-secondary capitalize'>Add new</span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='bg-[#EFF4FD] p-6 rounded-3xl mb-3'>
                        <div className="h4 text-[#7D8CA7] text-[1.1rem] ">Paragraph</div>
                        <div className="section6Main grid grid-cols-[3fr,7fr] gap-3 max-lg:grid-cols-1 max-lg:gap-0">
                            <div className="section6Main__left">
                                <FormControl name="sec_six_heading" label={"Heading 1"} placeholder="Enter Heading 1" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                            </div>
                            <div className="section6Main__Right">
                                <FormControl name="sec_six_discription" label={"Meta Paragraph"} placeholder="Enter Paragraph" className="outline-none w-full h-[5rem] px-5 py-3 rounded-xl resize-none" control="textarea2" />
                            </div>
                        </div>
                        <div className="sect6Main grid grid-cols-3 gap-4 mt-4 max-lg:grid-cols-1">
                            {
                                Array.isArray(sec_six) && sec_six.map((item, index) => {
                                    const {  heading, image, description, btn_label } = item
                                    return (
                                        <div className="section3MainBox bg-[#DEE5F2] p-4 rounded-lg relative" key={index}>
                                            <div className='closeButton cursor-pointer absolute right-[-.5rem] top-[-.5rem] ml-auto bg-[#FFCEDA] w-[2rem] h-[2rem] p-[.6rem] grid place-items-center rounded-[.7rem] z-10'
                                                onClick={() => handleDelete6(index)}  >
                                                <svg className='w-full h-full' width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M15.8612 1.34766L0.951172 16.2567M15.8612 16.2577L0.951172 1.34863" stroke="#ED2656" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                </svg>
                                            </div>
                                            <div className="inputBox w-full mt-3">
                                                <label htmlFor="" className="mb-1 text-[#7D8CA7] text-[.8rem] flex justify-between"><span>Heading</span>  <span>{(index + 1) >= 10 ? (index + 1) : `0${(index + 1)}`}</span></label>
                                                <input name="heading" placeholder="Enter Heading" className="outline-none w-full h-[3rem] px-5 rounded-xl" value={heading} onChange={(e) => handleInputChange(e, "sec_six", index)} />
                                            </div>
                                            <div className="inputBox w-full mt-3">
                                                <label htmlFor="" className="mb-1 block text-[#7D8CA7] text-[.8rem]">Paragraph</label>
                                                <textarea id="paragraph" name="description" placeholder="Paragraph" className="outline-none w-full h-[10rem] px-5 py-3 rounded-xl resize-none" value={description} onChange={(e) => handleInputChange(e, "sec_six", index)}></textarea>
                                            </div>
                                            <div>
                                                <label className="mb-1  mt-3 block text-[#7D8CA7] text-[.8rem]">Image</label>
                                                <div className=' h-[10rem] relative' onDrop={(e) => handleDrop2(e, "sec_six", index)}   >
                                                    {image ?
                                                        <img src={image || profile} className='w-full h-full rounded-3xl object-cover ' alt="" />
                                                        :
                                                        <div className='w-full h-full  rounded-3xl object-cover  bg-white'></div>
                                                    }
                                                    <div className="TeamBoxinput w-[3rem] h-[3rem] bg-[#C0CCE2] grid  place-items-center rounded-full absolute mx-auto right-0 left-0 top-[50%] transform translate-y-[-50%] cursor-pointer z-1">
                                                        <input type="file" onChange={(e) => handleFileUpload2(e, "sec_six", index)} className='absolute inset-0 opacity-0 cursor-pointer' />
                                                        <img src={camera} alt="camera" className='cursor-pointer w-full p-3' />
                                                    </div>
                                                </div>
                                            </div>
                                            {/* <div className="inputBox w-full mt-3">
                                                <label htmlFor="" className="mb-1 block text-[#7D8CA7] text-[.8rem]">BTN</label>
                                                <input name="btn_label" placeholder="Enter label" className="outline-none w-full h-[3rem] px-5 rounded-xl" value={btn_label} onChange={(e) => handleInputChange(e, "sec_six", index)} />
                                            </div> */}
                                           
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <Link className='bg-[#d9dcf8] w-fit mt-4 py-3 px-6 rounded-full flex items-center gap-2 cursor-pointer' onClick={handleSec6Add} >
                            <img src={plus} alt="plus" />
                            <span className='font-MluvkaBold text-secondary capitalize'>Add new</span>
                        </Link>
                    </div>
                    <SubmitButton
                        props={{
                            class: "btn bg-secondary text-white px-12 ml-auto uppercase mb-3   py-3 rounded-full w-100 block mt-5 submit hover:bg-primary transition-all duration-300",
                            text: "Submit",
                        }}
                        buttonLoading={res.isLoading}
                    />
                </Form>
            </Formik>
        </div>
    )
}

export default AboutUs