import { Form, Formik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import FormControl from '../components/form/FormControl'
import SkeletonElements from './SkeletonElements'
import UpdateButton from '../components/UpdateButton'
import useGet from '../customHooks/useGet'
import { MainLanguageContext } from '../context/MainLanguageContext'
import SubmitButton from '../components/SubmitButton'
import usePost from '../customHooks/usePost'
import { toast } from 'react-toastify'

const Elements = () => {
    const [loading, setLoading] = useState(true)

    const { mainLanguage } = useContext(MainLanguageContext);
    const [resget, apiMethodGet] = useGet()


      useEffect(() => {
        if (mainLanguage) {
          setLoading(true);
          apiMethodGet(`elements/element/${mainLanguage}`);
        }
      }, [mainLanguage]);

      useEffect(() => {
        if(resget?.data) {
          setLoading(false)
        }
    }, [resget.isLoading])
    const [res, apiMethod] = usePost()
    const handleSubmit = async (values) => {
        let formdata = new FormData();
        for (const item in values) {
            formdata.append(item, values[item]);
        }
        apiMethod(`elements/element/${mainLanguage}`, formdata)
    }

    useEffect(() => {
        if (res.data) {
          const { status, message } = res?.data
          if (status === "false") {
            toast.error(message);
          }
          else {
            toast.success(message);
          }
        }
      }, [res.data])

    if(resget.isLoading) return <SkeletonElements />
    const initialValues = resget?.data?.data || {}
    return (
        <div className='ElementsPage pr-10'>
            <div className='bg-[#EFF4FD] p-6 rounded-3xl'>
            <Formik initialValues={initialValues} onSubmit={handleSubmit}  >
                <Form>
                <FormControl name="home"  label={"home"} placeholder="Enter home" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="about"  label={"about"} placeholder="Enter about" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="services"  label={"services"} placeholder="Enter services" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="news-updates"  label={"News & Updates"} placeholder="Enter News & Updates" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="events"  label={"Events"} placeholder="Enter Events" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="ourteam"  label={"Our Team"} placeholder="Enter Team" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="gallery"  label={"gallery"} placeholder="Enter gallery" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="contact-us"  label={"Contact Us"} placeholder="Enter Contact Us" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="book-a-consultation"  label={"Book a Consultation"} placeholder="Enter Book a Consultation" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="News-and-updates"  label={"News and updates"} placeholder="Enter News and updates" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="news-and-judgements"  label={"News & Judgements"} placeholder="Enter News & Judgements" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="testimonials"  label={"Testimonials"} placeholder="Enter Testimonials" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="customer-reviews"  label={"Customer Reviews"} placeholder="Enter Customer Reviews" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="expertise"  label={"expertise"} placeholder="Enter expertise" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="diversity"  label={"diversity"} placeholder="Enter diversity" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="legal-library"  label={"legal library"} placeholder="Enter legal library" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="headquarters"  label={"headquarters"} placeholder="Enter headquarters" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="our-branches"  label={"our branches"} placeholder="Enter our branches" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="email"  label={"email"} placeholder="Enter email" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="phone"  label={"phone"} placeholder="Enter phone" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="fax"  label={"fax"} placeholder="Enter phone" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="get-a-quote"  label={"Get a Quote"} placeholder="Enter Get a Quote" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="download-our-company-profile"  label={"Download Our Company Profile"} placeholder="Enter Download Our Company Profile" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="connect-with-us"  label={"Connect with us"} placeholder="Enter Connect with us" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="view-all"  label={"View All"} placeholder="Enter View All" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="read-more"  label={"Read More"} placeholder="Enter Read More" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="published-by"  label={"Published by"} placeholder="Enter Published by" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="book-meeting"  label={"Book Meeting"} placeholder="Enter Book Meeting" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="enter-name"  label={"Enter name"} placeholder="Enter name" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="enter-email"  label={"Enter email"} placeholder="Enter email" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="number-of-attendance"  label={"Enter Number of Attendance"} placeholder="Enter Number of Attendance" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="choose-consultant"  label={"Enter choose consultant"} placeholder="Enter choose consultant" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="select-date"  label={"Enter Select date"} placeholder="Enter Select date" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="time-slot"  label={"Enter Time Slot"} placeholder="Enter Time Slot" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="beverage"  label={"Enter Beverage"} placeholder="Enter Beverage" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="book-now"  label={"Enter Book Now"} placeholder="Enter Book Now" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="reach-us"  label={"Enter Reach Us"} placeholder="Enter Reach Us" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="full-name"  label={"Enter Full Name"} placeholder="Enter Full Name" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="email-address"  label={"Enter Email Address"} placeholder="Enter Email Address" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="phone-number"  label={"Enter Phone Number"} placeholder="Enter Phone Number" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="message"  label={"Enter Message"} placeholder="Enter Message" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="education"  label={"Education"} placeholder="Enter Education" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="skills"  label={"Skills"} placeholder="Enter Skills" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="members"  label={"Members"} placeholder="Enter Members" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="practice_area"  label={"Practice Area"} placeholder="Enter Practice Area" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="related_articles"  label={"Related Articles"} placeholder="Enter Related Articles" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="perpose_meeting"  label={"Purpose of the meeting"} placeholder="Enter Purpose of the meeting" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="developed_by"  label={"Developed by"} placeholder="Enter Developed by" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="quick_digitals"  label={"Quick Digitals"} placeholder="Enter Quick Digitals" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="loading"  label={"loading"} placeholder="Enter loading" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="submit"  label={"Enter Submit"} placeholder="Enter Submit" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                <FormControl name="eventupdate"  label={"Enter Event Update"} placeholder="Enter Event Update" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                {(
                      <SubmitButton
                        props={{
                          class:
                            "btn bg-secondary text-white  uppercase mt-5   py-3 px-8 rounded-full  submit hover:bg-primary transition-all duration-300",
                          text: "update",
                        }}
                        buttonLoading={res.isLoading}
                      />
                    )}
                </Form>
            </Formik>
            </div>
        </div>
    )
}

export default Elements
