import React, { useContext, useEffect, useState } from 'react'
import { Form, Formik } from 'formik';
import FormControl from '../components/form/FormControl';
import swal from "sweetalert";
import profile from "../dist/webImages/profile2.png"
import camera from "../dist/webImages/camera.svg"
import plus from '../dist/webImages/plus.svg'
import SubmitButton from '../components/SubmitButton';
import CKEditors from '../components/form/CKEditors';
import { Link, useNavigate, useParams } from 'react-router-dom';
import back from "../dist/webImages/back.svg";
import SkeletonCreateEdit from './SkeletonCreateEdit';
import { MainLanguageContext } from '../context/MainLanguageContext';
import usePost from '../customHooks/usePost';
import useGet from '../customHooks/useGet';
import { toast } from 'react-toastify';
import useFetch from '../customHooks/useFetch';
import noimg from '../dist/webImages/no_image.jpg'

const EditDepartments = () => {
    const {id} = useParams();
    const navigate = useNavigate();
    const { loading2, data } = useFetch(`teams/en/100?page=1`);
    const { mainLanguage } = useContext(MainLanguageContext); 
    const [loading, setLoading] = useState(true)
    const [resget, apiMethodGet] = useGet()
    useEffect(() => {
        if (mainLanguage) {
          setLoading(true);
          apiMethodGet(`departments/singleDetail/${id}/${mainLanguage}`);
        }
      }, [mainLanguage]);
    const [datas, setDatas] = useState({
        "discription": "",
    });
    const { discription } = datas;
 
    const [imgValue, setiImgValue] = useState("");
    const [imgPath, setImgPath] = useState();
    const [lowyers, setLowyers] = useState([])
    const [addLowyer, setAddLowyer] = useState({
        "addLowyerStatus": false,
        "LowyerList": []
    })
    const { addLowyerStatus, LowyerList } = addLowyer
    useEffect(() => {
        if(data) {
         setAddLowyer((prevdata) => ({ ...prevdata,  LowyerList: data?.data?.teams}));
        }
         }, [data])
    useEffect(() => {
        if(resget?.data) {
          setLoading(false)
          const {department_image,description,teams} = resget?.data?.data
          setImgPath(department_image)
          setDatas({...datas,"discription":description})
            if(teams) {
                setLowyers(teams)
            }
            else {
                setLowyers([])
            }
       
        }
      }, [resget.isLoading])

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        if (file) {
            let path = (window.URL || window.webkitURL).createObjectURL(file);
            setImgPath(path);
            setiImgValue(file)
        }
    }
    const handleDrop = (e) => {
        e.preventDefault();
        if (e.dataTransfer.files[0] && e.dataTransfer.files[0]?.type?.includes("image")) {
            let path = (window.URL || window.webkitURL).createObjectURL(e.dataTransfer.files[0]);
            setImgPath(path);
            const file = e.dataTransfer.files[0];
            setiImgValue(file)
            // onUpload(file);
        }
        else {
            swal("Only use Image", "", "warning");
        }
    };
    const handleRemoveLowyers = (index) => {
        swal({
            title: "Are you sure?",
            text: "you want to remove team member?",
            buttons: true,
            icon: "warning",
            dangerMode: true,
        })
            .then(willDelete => {
                if (willDelete) {
                    setLowyers(prevExpertise => prevExpertise.filter((_, i) => i !== index));
                    swal("Successfully Delete", "", "success");
                }

            });
    };
    const handlediscriptionChange = (e) => {
        setDatas(d => ({ ...d, "discription": e }));
    };
    const handleLawyersToggle = (id) => {
        setLowyers(prevState => {
            const isActive = prevState.some(item => item.id === id);
            if (isActive) {
                return prevState.filter(item => item.id !== id);
            } else {
                const newLowyer = LowyerList.find(item => item.id === id);
                return [...prevState, newLowyer];
            }
        });
    }
    const [res, apiMethod] = usePost()
    const requireFeild = ["meta_tag","meta_description","title"];
    const handleSubmit = async (values) => {
        let formdata = new FormData();
        let requireFeildSwal = {
            meta_tag: "Meta Tag",
            meta_description: "Meta Description",
            title: "Title",
        };
        let checkerRequried = [];
        for (const item in values) {
          if (requireFeild.includes(item) && values[item] === "") {
            checkerRequried.push(requireFeildSwal[item]);
          }
        }
        formdata.append("department_translation[meta_tag]", values["meta_tag"]);
        formdata.append("department_translation[meta_description]", values["meta_description"]);
        formdata.append("department_translation[schema_code]", values["schema_code"]);
        formdata.append("department_translation[title]", values["title"]);
        console.log(values)
        if (!imgPath) {
            checkerRequried.push("Image");
          }
          if (lowyers.length === 0) {
              checkerRequried.push("team");
          }
      
          if (checkerRequried.length > 0) {
            swal({
              title: "Required Fields are empty! Please fill and try again",
              text: checkerRequried.join(","),
              icon: "error",
              dangerMode: true,
            });
          }
          else {
            formdata.append("department_image", imgValue);
            formdata.append("department_translation[description]", discription);
            for (let index = 0; index < lowyers.length; index++) {
                formdata.append("team_member[]", lowyers[index]["id"]);
                
              }
            apiMethod(`departments/${id}/${mainLanguage}`,formdata)
          }
    }
    useEffect(() => {
        if(res.data) {
          const {status,message} = res?.data
          if(status === "false") {
            toast   .error(message);
          }
          else {
            navigate("/departments")
            toast.success(message);
          }
        }
      }, [res.data])

    if(resget.isLoading || loading || loading2) return <SkeletonCreateEdit heading={"Edit Departments"} />
    let initialValues = {
        meta_tag: resget?.data?.data?.meta_tag,
        meta_description: resget?.data?.data?.meta_description,
        schema_code: resget?.data?.data?.schema_code,
        title: resget?.data?.data?.title,
    }
    return (
        <div className='EditDepartments pr-10 max-lg:pr-6'>
             <Link to={"/departments"} className="back flex items-center mb-5 gap-2">
            <img src={back} className='w-[2rem]' alt="" />
            <span className='text-[1.4rem] font-MluvkaBold'>Edit Department</span>
            </Link>
            <div className='bg-[#EFF4FD] p-6 rounded-3xl mb-3 max-lg:p-3'>
                <div className='relative flex'>
                    <div className=' bg-white rounded-xl w-full  mx-auto relative'>
                        <div className='overflow-auto modelBox'>
                            <Formik initialValues={initialValues}
                                onSubmit={handleSubmit}>
                                <Form name="myForm">
                                <div className='bg-[#EFF4FD] py-4'>
                  <FormControl name="meta_tag" label={"Meta Title"} placeholder="Enter Meta Title" className="outline-none w-full h-[3rem] px-5 rounded-xl" control="input2" />
                  <div className='grid grid-cols-2 gap-3 max-lg:grid-cols-1 max-lg:gap-0'>
                    <FormControl name="meta_description" label={"Meta Description"} placeholder="Enter Meta Description" className="outline-none w-full h-[10rem] px-5 py-3 rounded-xl resize-none" control="textarea2" />
                    <FormControl name="schema_code" label={"Scheme Code"} placeholder="Scheme Code" className="outline-none w-full h-[10rem] px-5 py-3 rounded-xl resize-none" control="textarea2" />
                  </div>
                </div>
               
                                    <div className="DepartmentsBox p-5 px-12 rounded-xl max-lg:py-3 max-lg:px-5">
                                        <div className='w-[12rem] h-[12rem] relative mt-4' onDrop={handleDrop}   >
                                            <img src={imgPath || profile} className='w-full h-full rounded-3xl object-cover border-2 border-[#C0CCE2]' alt="" />
                                            <div className="TeamBoxinput w-[3rem] h-[3rem] bg-[#C0CCE2] grid  place-items-center rounded-full absolute mx-auto right-0 left-0 top-[50%] transform translate-y-[-50%] cursor-pointer z-1">
                                                <input type="file" onChange={handleFileUpload} className='absolute inset-0 opacity-0 cursor-pointer' />
                                                <img src={camera} alt="camera" className='cursor-pointer w-full p-3' />
                                            </div>
                                        </div>
                                        <div className='form mt-7'>
                                            <FormControl name="title" placeholder="Enter Department Title" className="outline-none w-full h-[2.7rem] border border-[#CFD5E2] px-5 rounded-full" control="input" />
                                            <CKEditors label={"discription"} data={discription} update={handlediscriptionChange} />
                                            <div className='flex items-center gap-5 mt-3 max-lg:flex-col'>
                                                <div className='border border-[#CFD5E2] py-3 px-6 rounded-full flex items-center gap-2 cursor-pointer' onClick={() => setAddLowyer(d => ({ ...d, addLowyerStatus: !addLowyerStatus }))}>
                                                    <img src={plus} alt="plus" />
                                                    <span className='font-MluvkaBold text-secondary capitalize'>Add Teams</span>
                                                </div>
                                                <ul className='list flex max-lg:flex-wrap gap-2'>
                                                {lowyers && lowyers.map((item, index) => {
                                                        const { id, lowyer_image } = item;
                                                        return (
                                                            <li className="dpartCardProfile-img relative" key={id}>
                                                                <div className='close w-[1.2rem] h-[1.2rem] grid place-items-center p-1 absolute top-[-.2rem] right-[-.2rem] bg-[#FF9898] rounded-3xl cursor-pointer' onClick={() => handleRemoveLowyers(index)}>
                                                                    <svg width="7" height="7" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path d="M6.22583 1.00391L1.19141 6.03799M6.22583 6.03833L1.19141 1.00423" stroke="#FF0000" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                                                    </svg>
                                                                </div>
                                                                <img className={`w-[2.8rem] h-[2.8rem] min-w-[2.8rem] min-h-[2.8rem] rounded-full object-cover border-2 border-[#fff]`} src={lowyer_image} alt={id} />
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </div>
                                        </div>

                                    </div>
                                    <ul className='list gap-4  my-3 pb-6 px-6'>
                                        <li className=''>
                                            {
                                                <SubmitButton
                                                    props={{
                                                        class: "btn bg-secondary text-white  uppercase ml-auto  py-3 px-10 rounded-full w-fit block submit hover:bg-primary transition-all duration-300",
                                                        text: "Update",
                                                    }}
                                                    buttonLoading={res.isLoading}
                                                />
                                            }
                                        </li>
                                    </ul>
                                </Form>
                            </Formik>
                        </div>
                    </div>
                    <div className='bg-[#D4DEF1] w-0 overflow-hidden  transition-all duration-700  self-stretch rounded-xl max-lg:absolute max-lg:right-0 max-lg:w-[20rem!important]' style={{ width: addLowyerStatus ? "30%" : "0" }}>
                        {addLowyerStatus && <div className='px-3 h-full  py-4'>
                            <div className="h4 text-[1.1rem] font-MluvkaBold mb-4">
                                <span>Teams</span>
                                <div className='closeButton cursor-pointer absolute bg-[#FFCEDA] w-[2rem] h-[2rem] p-[.6rem] top-[.7rem] right-[.7rem] grid place-items-center rounded-[.7rem] z-10' onClick={() => setAddLowyer(d => ({ ...d, addLowyerStatus: !addLowyerStatus }))}>
                                    <svg className='w-full h-full' width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M15.8612 1.34766L0.951172 16.2567M15.8612 16.2577L0.951172 1.34863" stroke="#ED2656" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                            </div>
                            <ul className='list lowyerlist overflow-y-auto max-h-[100vh] pr-2'>
                            {Array.isArray(LowyerList) && LowyerList.map((item) => {
                                    const { id, name, lowyer_image, designation } = item;
                                    const lowyerslistactive = lowyers.map(l => l.id);
                                    return (
                                        <li key={id} className='bg-white cursor-pointer my-3 border border-white rounded-3xl py-2 px-4' onClick={() => handleLawyersToggle(id)} style={{ borderColor: lowyerslistactive.includes(id) ? "#DCB33E" : "white" }}>
                                            <div className="lawyerCard_ flex items-center gap-4">
                                                <div className="lawyerCard__img">
                                                    <img className='w-[2.5rem] h-[2.5rem] object-cover rounded-sm' onError={(e) => { e.target.src = noimg }} src={lowyer_image} alt={name} />
                                                </div>
                                                <div className="lawyerCard__txt" >
                                                    <div className="h2 text-[.9rem] font-Mluvka leading-[1]">{name}</div>
                                                    <p className='leading-[1] mt-1 text-primary text-[.8rem]'>{designation}</p>
                                                </div>
                                            </div>
                                        </li>
                                    )
                                })}

                            </ul>
                        </div>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditDepartments
