import React, { useContext, useEffect, useState } from 'react'
import plus from '../dist/webImages/plus.svg'
import SkeletonHeading from '../components/SkeletonHeading';    
import { Link } from 'react-router-dom';
import { MainLanguageContext } from '../context/MainLanguageContext';
import useGet from '../customHooks/useGet';
import { Pagination } from 'antd';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import usePost from '../customHooks/usePost';
import DraggableLegalSecretaryCard from './DraggableLegalSecretaryCard';
import SkeletonLegalSecretaryCard from './SkeletonLegalSecretaryCard';

const LegalSecretary = () => {
    const { mainLanguage } = useContext(MainLanguageContext);
    const [datas, setDatas] = useState();
    const [resget, apiMethodGet] = useGet();
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize, setPageSize] = useState(8);
    const [paginationn, setPaginationn] = useState(8);
    const [searchValue, setSearchValue] = useState("")

    const onChange = (current, pageSize) => {
        setPageSize(pageSize)
        setCurrentPage(current);
        let formdata = new FormData();
        formdata.append('search_query', searchValue);
        if ((searchValue).trim()) {
            apiMethod2(`searchlist/search_secretary_list/${mainLanguage}/8?page=${current}`, formdata);
        } else {
            apiMethodGet(`legalSecretaries/${mainLanguage}/${pageSize}?page=${current}`, formdata);
        }
    };

    useEffect(() => {
        if (mainLanguage) {
            setCurrentPage(1)
            apiMethodGet(`legalSecretaries/${mainLanguage}/${pageSize}?page=1`);
        }
    }, [mainLanguage]);



    const [res, apiMethod] = usePost()
    const moveCard = (fromIndex, toIndex, draggedItem, ite) => {
        console.log(draggedItem)
        const updatedData = [...datas];
        const [movedItem] = updatedData.splice(draggedItem.order_number, 1);
        updatedData.splice(ite.order_number, 0, movedItem);

        if (ite.order_number !== draggedItem.order_number) {
            // setDatas(updatedData);
            let formdata = new FormData();
            formdata.append(`order_number[id][${draggedItem.id}]`, ite.order_number ?? "");
            formdata.append(`order_number[id][${ite.id}]`, draggedItem.order_number ?? "");
            console.log(`order_number[id][${draggedItem.id}]`, ite.order_number, `order_number[id][${ite.id}]`, draggedItem.order_number)
            apiMethod(`teamorder/updateorder`, formdata)
        }
    };

    useEffect(() => {
        if (res.data) {
            let formdata = new FormData();
            formdata.append('search_query', searchValue);
            if ((searchValue).trim()) {
                apiMethod2(`searchlist/search_secretary_list/${mainLanguage}/8?page=${currentPage}`, formdata);
            } else {
                apiMethodGet(`legalSecretaries/${mainLanguage}/${pageSize}?page=${currentPage}`, formdata);
            }
        }
    }, [res.data])

    const handleD = (item) => {
        let formdata = new FormData();
        formdata.append('search_query', searchValue);
        if ((searchValue).trim()) {
            apiMethod2(`searchlist/search_secretary_list/${mainLanguage}/8?page=${currentPage}`, formdata);
        } else {
            apiMethodGet(`legalSecretaries/${mainLanguage}/${pageSize}?page=${currentPage}`, formdata);
        }
    }


    const [res2, apiMethod2] = usePost()
    let debounceTimer;
    const debounce = (func, delay) => {
        return (...args) => {
          clearTimeout(debounceTimer);
          debounceTimer = setTimeout(() => {
            func(...args);
          }, delay);
        };
      };
    
    
      const executeApiCall = (e) => {
        setSearchValue(e.target.value)
        let formdata = new FormData();
        formdata.append('search_query', e.target.value);
        if ((e.target.value).trim()) {
            apiMethod2(`searchlist/search_secretary_list/${mainLanguage}/8?page=1`, formdata);
        } else {
            apiMethodGet(`legalSecretaries/${mainLanguage}/${pageSize}?page=${currentPage}`, formdata);
        }
      };
    
      const handleChange = debounce(executeApiCall, 1000)
    useEffect(() => {
        setDatas([])
        if (!resget.isLoading) {
            setDatas(resget?.data?.data);
            setPaginationn(resget?.data?.pagination)
        }
    }, [resget.data]);
    useEffect(() => {
        console.log([])
        setDatas([])
        if (res2.data) {
            setDatas(res2?.data?.data);
            setPaginationn(res2?.data?.pagination)
        }
    }, [res2.data]);

    return (
        <DndProvider backend={HTML5Backend}>
            <div className='TeamPage pr-10 max-lg:pr-6'>
                <div className="TeamPageTop flex justify-between items-center">

                    {(resget.isLoading) ? <SkeletonHeading /> : <h6 className='text-[1rem] mb-2 bookingSectionh relative px-3 font-Mluvka'><span>{datas?.length && resget.data?.pagination?.total}</span> Legal Secretary</h6>}
                    <div className='flex gap-1'>
                        <div className="inputBox w-[16rem] max-lg:hidden">
                            <input type="text" onChange={handleChange} className='w-full border h-[2.8rem] rounded-full px-4 border-[#ddd] outline-none' placeholder='Search' />
                        </div>
                        <Link to={"/legalsecretary/create"} className='bg-[#d9dcf8] py-3 px-6 rounded-full flex items-center gap-2 cursor-pointer'>
                            <img src={plus} alt="plus" />
                            <span className='font-MluvkaBold text-secondary capitalize'>Add new</span>
                        </Link>

                    </div>
                </div>
                <div className="TeamPageGrid mt-4 bg-[#EFF4FD] rounded-3xl p-6 grid grid-cols-4 gap-3 max-lg:grid-cols-1 max-lg:p-2 ">
                    {(resget.isLoading) ?
                        Array.from({ length: 8 }).map((_, index) => (
                            <React.Fragment key={index}>
                                <SkeletonLegalSecretaryCard />
                            </React.Fragment>
                        ))
                        :
                        Array.isArray(datas) && datas.map((item, index) => (
                            <DraggableLegalSecretaryCard
                                key={index}
                                item={item}
                                index={index}
                                id={item.id}
                                order_number={item.order_number}
                                // moveCard={moveCard} 
                                delet={(item) => handleD(item)}
                                data={datas}
                            />
                        ))}
                </div>

               {!resget.isLoading && <div className='my-4'>
                    <Pagination
                        onChange={onChange}
                        defaultCurrent={currentPage}
                        total={paginationn?.total}
                        pageSize={pageSize}
                        showSizeChanger
                    />
                </div>}
            </div>
        </DndProvider>
    );
};

export default LegalSecretary;

