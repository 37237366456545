import React, { useContext, useEffect, useState } from 'react'
import BookingsCard from '../Bookings/BookingsCard'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import BookingModel from '../Bookings/BookingModel';
import config from "../services/config.json"
import { Link } from 'react-router-dom';
import DepartmentsCard from '../Departments/DepartmentsCard';
import LawyersCard from '../Lawyers/LawyersCard';
import SkeletonBookingsCard from '../Bookings/SkeletonBookingsCard';
import SkeletonDepartmentsCard from '../Departments/SkeletonDepartmentsCard';
import SkeletonLawyersCard from '../Lawyers/SkeletonLawyersCard';
import { MainLanguageContext } from '../context/MainLanguageContext';
import useGet from '../customHooks/useGet';
import { MainBookingContext } from '../context/MainBooking';

const MainDashboard = () => {
    const { mainLanguage } = useContext(MainLanguageContext);
    const [resget, apiMethodGet] = useGet();
    const [loading, setLoading] = useState(true)
    const [datas, setDatas] = useState();
    useEffect(() => {
        if (mainLanguage) {
            apiMethodGet(`dashboard/bookings/${mainLanguage}`);
        }
    }, [mainLanguage]);
    useEffect(() => {
        if (!resget.isLoading) {
            setLoading(false)
            setDatas(resget?.data?.data);
        }
    }, [resget.data]);
    const handleForm = () => {
        apiMethodGet(`dashboard/bookings/${mainLanguage}`);
    }

    const [modelBookingData, setModelBookingData] = useState(null)
    const [modelBookingStatus, setModelBookingStatus] = useState(false)
    return (
        <section className='mainDahboard'>
            <div className='bookingSection max-lg:pr-6'>
                <h6 className='text-[1rem] mb-2 bookingSectionh relative px-3 font-Mluvka'>Bookings</h6>
                <div className='bookingGrid '>
                    <Swiper
                        slidesPerView={"auto"}
                        spaceBetween={30}
                        speed={1000}
                        autoplay={{
                            delay: 2500,
                        }}
                    >
                        {resget.isLoading ? 
                          Array.from({ length: 5 }).map((_, index) => (
                            <SwiperSlide key={index}>
                                <SkeletonBookingsCard />
                            </SwiperSlide>
                        ))
                        : datas?.bookings.map((item, index) => {
                            return (
                                <SwiperSlide key={index}>
                                    {<BookingsCard data={item} modelDataUpdate={setModelBookingData} modelStatusUpdate={setModelBookingStatus} />}
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                    {modelBookingStatus && <BookingModel submitss={() => handleForm()} data={modelBookingData} modelStatus={modelBookingStatus} modelStatusUpdate={setModelBookingStatus} />}
                </div>
            </div>
            <div className="mainDahboard2 grid grid-cols-2 gap-6 mt-6 pr-10 max-lg:grid-cols-1 max-lg:pr-6">
                <div className='departments bg-[#EFF4FD] p-6 rounded-xl'>
                    <ul className='list'>
                        <li className='departments_top flex justify-between items-center'>
                            <h6 className='text-[1rem] mb-2 bookingSectionh relative px-3 font-Mluvka'>Departments</h6>
                            <div className="departments__right flex items-center gap-8">
                                <Link to={`${config.demo}/departments/create`} className='cursor-pointer text-secondary font-Mluvka' >+ Add new</Link>
                                <Link to={`${config.demo}/departments`} className='font-Mluvka'>View All</Link>
                            </div>
                        </li>
                    </ul>
                    <div className="departmentsBody grid grid-cols-2 gap-3 mt-4 max-md:grid-cols-1">
                        
                        {
                            resget.isLoading ? 
                         Array.from({ length: 4 }).map((_, index) => (
                            <React.Fragment key={index}>
                            <SkeletonDepartmentsCard  page={"home"} />
                        </React.Fragment>
                         ))
                        :
                        datas?.departments.slice(0,4).map((item, index) => {
                            return (
                                <React.Fragment key={index}>
                                    <DepartmentsCard data={item} page={"home"} />
                                </React.Fragment>
                            )
                        })}
                      </div>
                </div>
                {/* close departments */}
                <div className="lawyers bg-[#EFF4FD] p-6 rounded-xl">
                <ul className='list'>
                        <li className='departments_top flex justify-between items-center'>
                            <h6 className='text-[1rem] mb-2 bookingSectionh relative px-3 font-Mluvka'>Our Teams</h6>
                            <div className="departments__right flex items-center gap-8">
                                <Link to={`${config.demo}/team/create`} className='cursor-pointer text-secondary font-Mluvka'>+ Add new</Link>
                                <Link to={`${config.demo}/team`} className='font-Mluvka'>View All</Link>
                            </div>
                        </li>
                    </ul>
                    <div className="lawyersBody grid grid-cols-2 gap-3 mt-4 max-md:grid-cols-1">
                    {resget.isLoading ? 
                         Array.from({ length: 6 }).map((_, index) => (
                            <React.Fragment key={index}>
                            <SkeletonLawyersCard  page={"home"} />
                        </React.Fragment>
                         ))
                        :
                        datas?.teams.slice(0,8).map((item, index) => {
                            return (
                                <React.Fragment key={index}>
                                <LawyersCard data={item}  page={"home"} />
                                </React.Fragment>
                            )
                        })}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default MainDashboard
